
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { Md5 } from 'ts-md5/dist/md5'
import {
    getModelList,
  addModel,
  editModel,
  isEditAuthByCode
} from '@/api/index'
interface deviceDataF {
  loading: boolean
  rules: any
  pageSize: number
  pageSizes: Array<number>
  currentPage: number
  total: number
  customerData: Array<any>
  editFlag: boolean
  editForm: any
  searchDataAny: any //搜索条件
}

export default defineComponent({
  name: 'modelTable',
  setup() {
    const productSpecificationsRef = ref()
    const deviceData: deviceDataF = reactive({
      loading: true,
      editFlag: false,
      editForm: {},
      searchDataAny: {}, //搜索条件

      customerData: [],
      total: 0,
      pageSize: 10,
      pageSizes: [10, 30, 50, 100],
      currentPage: 1,
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
    })

    const data = toRefs(deviceData)
    onMounted(() => {
          setTimeout(function(){
              getList()
          },50);
    })

    const addFun = () => {
      deviceData.editForm = { isEdit: false }
      deviceData.editFlag = true
    }

    const editSave = async () => {
      productSpecificationsRef.value.validate(async (valid: any) => {
        if (valid) {
          let fun = addModel
          if (deviceData.editForm.isEdit) {
            fun = editModel
            delete deviceData.editForm.createTime
          }

          let array: any = {}
          Object.assign(array, deviceData.editForm)

          fun(array).then((res: any) => {
            let { code } = res
            if (code == 0) {
              deviceData.editFlag = false
              getList()
            } else {
              ElMessage.error(res.errMsg)
            }
          })
        } else {
          return false
        }
      })
    }

    const selectCustomer = () => {
      deviceData.currentPage = 1
      getList()
    }

    //编辑
    const editorFun = (e: any) => {
      //console.log(e)
      let obj: any = {}
      Object.assign(obj, e)
      deviceData.editForm = obj
      deviceData.editForm.isEdit = true
      deviceData.editFlag = true
    }

    const getList = async () => {
        //deviceData.customerData = [];
      deviceData.searchDataAny.length = deviceData.pageSize
      deviceData.searchDataAny.page = deviceData.currentPage
      deviceData.searchDataAny.isPage = true
        deviceData.loading = true
      await getModelList(deviceData.searchDataAny).then(
        (res: any) => {
          if (res.code == 0) {
              if(res.data!=null && res.data.list!=null){
                  deviceData.customerData = res.data.list
                  deviceData.total = res.data.total
              }else{
                  deviceData.customerData = [];
                  deviceData.total = 0;
                  ElMessage.success('加载成功，数据为空！');
              }
            
          setTimeout(function(){
              deviceData.loading = false
          },100);
          
            // ElMessage.success('加载成功!')
          }else if(res.errMsg!=null){
            ElMessage.error(res.errMsg);
              deviceData.loading = false
        }else{
            ElMessage.error("加载失败");
              deviceData.loading = false
        }
        }
      )
    }

    const handleSizeChange = (val: any) => {
      //设置当前页显示几条数据
      deviceData.pageSize = val
      getList()
    }
    const handleCurrentChange = (val: any) => {
      //当前页码
      deviceData.currentPage = val
      getList()
    }


    return {
      ...data,
      addFun,
      productSpecificationsRef,
      editSave,
      selectCustomer,
      editorFun,
      handleSizeChange,
      handleCurrentChange,
      isEditAuthByCode
    }
  },
})
